import { IterableElement } from "type-fest";
import { PortfolioRouteQuery } from "../../../../generated/urql";
import * as U_M from "@heritageholdings/lib-commons-finance/lib/units/money";
import { parseMoneyCurrency } from "../../../../utils/data/SimpleMoney";
import { Currency } from "@heritageholdings/lib-commons-finance/lib/units/MoneyNext";

/**
 *
 */
export const genPortfolioAssetsTotals = (
  situation: IterableElement<
    PortfolioRouteQuery["investor"]["portfolioSituation"]
  >,
  currency: Currency,
) => {
  return situation.byAllocation.reduce(
    (acc, next) => {
      const allocationCurrency = parseMoneyCurrency(next.allocation.currency);

      if (allocationCurrency !== currency) {
        return acc;
      }

      const commitment = new U_M.Money({
        [allocationCurrency]: next.allocation.amountUsd,
      });
      const distributed = new U_M.Money({
        [allocationCurrency]: next.distributedUsd,
      });
      const paidIn = new U_M.Money({
        [allocationCurrency]: next.paidInUsd,
      });
      const profit = new U_M.Money({
        [allocationCurrency]: next.profit,
      });
      const nav = new U_M.Money({
        [allocationCurrency]: next.navUsd,
      });
      const totalValue = new U_M.Money({
        [allocationCurrency]: next.totalValue,
      });
      const unfunded = new U_M.Money({
        [allocationCurrency]: next.unfunded,
      });
      const unpaid = new U_M.Money({
        [allocationCurrency]: next.unpaid,
      });
      const sourcePaidIn = new U_M.Money({
        [allocationCurrency]: next.sourcePaidInUsd,
      });

      return {
        commitment: acc.commitment.plus(commitment).selectCurrency(currency),
        distributed: acc.distributed.plus(distributed).selectCurrency(currency),
        paidIn: acc.paidIn.plus(paidIn).selectCurrency(currency),
        profit: acc.profit.plus(profit).selectCurrency(currency),
        nav: acc.nav.plus(nav).selectCurrency(currency),
        totalValue: acc.totalValue.plus(totalValue).selectCurrency(currency),
        unfunded: acc.unfunded.plus(unfunded).selectCurrency(currency),
        unpaid: acc.unpaid.plus(unpaid).selectCurrency(currency),
        sourcePaidInUsd: acc.sourcePaidInUsd
          .plus(sourcePaidIn)
          .selectCurrency(currency),
      };
    },
    {
      commitment: U_M.zero,
      distributed: U_M.zero,
      paidIn: U_M.zero,
      profit: U_M.zero,
      nav: U_M.zero,
      totalValue: U_M.zero,
      unfunded: U_M.zero,
      unpaid: U_M.zero,
      sourcePaidInUsd: U_M.zero,
    },
  );
};

/**
 *
 */
export const genPortfolioAssetsTotalsRatios = (
  totals: ReturnType<typeof genPortfolioAssetsTotals>,
  currency: Currency,
) => {
  const distributed = totals.distributed.amounts()[currency] ?? 0;
  const totalValue = totals.totalValue.amounts()[currency] ?? 0;
  const paidIn = totals.paidIn.amounts()[currency] ?? 0;

  return paidIn !== 0
    ? {
        dpi: distributed / paidIn,
        tvpi: totalValue / paidIn,
      }
    : {
        dpi: 0,
        tvpi: 0,
      };
};
