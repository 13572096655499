import React from "react";
import { useMemo } from "react";
import * as U_Show from "@heritageholdings/lib-commons-finance/lib/show";
import { CashflowStats } from "../../data/CashflowStats";
import Grid, { Grid2Props } from "@mui/material/Unstable_Grid2";
import { Numeric } from "@heritageholdings/lib-commons-finance/lib/units/numeric";
import { StatBox } from "../../../core/v2/StatBox/StatBox";

type Props = {
  stats: CashflowStats;
  totalAssets: number;
  activeAssets: number;
};

/**
 * A component that will render the stats of the cashflow simulator.
 */
export const CashflowSimulatorStats: React.FC<Props> = ({ stats }) => {
  const formattedCommittedCapital = useMemo(
    () =>
      U_Show.currencyWebAppGeneric("USD").show(
        stats.committedCapital.toNormalizedAmount(),
      ),
    [stats.committedCapital],
  );

  const formattedExpectedProfit = useMemo(
    () =>
      U_Show.currencyWebAppGeneric("USD").show(
        stats.expectedProfit.toNormalizedAmount(),
      ),
    [stats.expectedProfit],
  );

  const expectedMoM = useMemo(
    () =>
      (stats.expectedProfit.over(stats.committedCapital) ?? new Numeric(0))
        .withLabel("Expected TVPI")
        .plus(new Numeric(1))
        .value(),
    [stats.expectedProfit, stats.committedCapital],
  );

  const formattedExpectedMoM = useMemo(
    () => U_Show.multiple1WithoutSuffix.show(expectedMoM),
    [expectedMoM],
  );

  const formatedPaidInPercentage = useMemo(() => {
    const percentage = stats.paidIn.over(stats.committedCapital);
    const percentageNum = percentage?.value();

    return typeof percentageNum === "number"
      ? U_Show.percentNoDecimal.show(percentageNum)
      : "-";
  }, [stats]);

  const commonStatsGridProps = useMemo<Grid2Props>(
    () => ({
      sm: 12,
      md: 4,
    }),
    [],
  );

  return (
    <Grid container spacing={2}>
      <Grid {...commonStatsGridProps}>
        <StatBox
          value={formattedCommittedCapital}
          title="Committed capital"
          subTitle={`Paid-in: ${formatedPaidInPercentage}`}
        />
      </Grid>
      <Grid {...commonStatsGridProps}>
        <StatBox
          value={formattedExpectedProfit}
          title="Expected profit"
          subTitle={`By ${stats.expectedProfitLastYear}`}
          highlightValue
        />
      </Grid>
      <Grid {...commonStatsGridProps}>
        <StatBox
          value={formattedExpectedMoM}
          symbol="×"
          title="Expected TVPI"
          subTitle="Total Value over Paid-In"
        />
      </Grid>
    </Grid>
  );
};
