import React, { PropsWithChildren, useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import { AttributeChip } from "../../../core/v2/Chip/Chip";
import { SingleAsset } from "../../../assets/v2/data/SingleAsset";
import * as U_Show from "@heritageholdings/lib-commons-finance/lib/show";
import { function as F, option as O } from "fp-ts";
import { Box } from "../../../core/v2/Box/Box";

const valueFallback = "-";

const StackBox: React.FC<PropsWithChildren> = ({ children }) => (
  <Box flex="1 1 auto" maxWidth="350px">
    {children}
  </Box>
);

type Props = {
  asset: SingleAsset;
};

/**
 * A list of informations about an asset.
 */
export const AssetInfo: React.FC<Props> = ({ asset }) => {
  const formattedIRR = useMemo(
    () =>
      F.pipe(
        asset.targetGrossIrr,
        O.fromNullable,
        O.match(
          () => valueFallback,
          F.pipe(U_Show.percentDoubleDecimal, U_Show.percentToFloat).show,
        ),
      ),
    [asset.targetGrossIrr],
  );

  return (
    <Stack gap={4} direction="row" flexWrap="wrap">
      <StackBox>
        <Typography variant="h6">Strategy</Typography>

        <Box mt={0}>
          <Stack gap={0} direction="row" flexWrap="wrap">
            {asset.diversification.strategies.map(({ name }) => (
              <AttributeChip key={name}>{name}</AttributeChip>
            ))}
          </Stack>
        </Box>
      </StackBox>

      <StackBox>
        <Typography variant="h6">Geography</Typography>

        <Box mt={0}>
          <Stack gap={0} direction="row" flexWrap="wrap">
            {asset.diversification.geographies.map(({ name }) => (
              <AttributeChip key={name}>{name}</AttributeChip>
            ))}
          </Stack>
        </Box>
      </StackBox>

      <StackBox>
        <Typography variant="h6">Industry</Typography>

        <Box mt={0}>
          <Stack gap={0} direction="row" flexWrap="wrap">
            {asset.diversification.industries.map(({ name }) => (
              <AttributeChip key={name}>{name}</AttributeChip>
            ))}
          </Stack>
        </Box>
      </StackBox>

      {asset.targetGrossMom ? (
        <StackBox>
          <Typography variant="h6">Historical Net TVPI</Typography>

          <Box mt={0}>
            <Typography variant="figuresNumber">
              {U_Show.multiple2WithoutSuffix.show(asset.targetGrossMom)}
            </Typography>

            <Typography variant="figuresSymbol">×</Typography>
          </Box>
        </StackBox>
      ) : undefined}

      <StackBox>
        <Typography variant="h6">Historical Net IRR</Typography>

        <Box mt={0}>
          <Typography variant="figuresNumber">{formattedIRR}</Typography>
        </Box>
      </StackBox>
    </Stack>
  );
};
