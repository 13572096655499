import { Paper, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { startOfQuarter } from "date-fns";
import { option as O } from "fp-ts";
import React, { useMemo } from "react";
import { useQuery } from "urql";
import {
  PortfolioAmountsChartDocument,
  PortfolioAmountsChartQuery,
  PortfolioAmountsChartQueryVariables,
} from "../../../../generated/urql";
import { useInvestor } from "../../../../hooks/useInvestor";
import { useRetrieveCutoffs } from "../../../../hooks/useRetrieveCutoffs";
import { formatCutoffDate, getTodayCutoff } from "../../../../utils/cutoff";
import { disableSuspenseContext } from "../../../../utils/queries";
import { Box } from "../../../core/v2/Box/Box";
import { SkeletonLoader } from "../../../core/v2/Loader/SkeletonLoader";
import { NoDataPlaceholder } from "../../../core/v2/NoDataPlaceholder/NoDataPlaceholder";
import { UnderlineH3 } from "../../../core/v2/UnderlineH3/UnderlineH3";
import {
  PortfolioAmountsChart,
  PortfolioAmountsChartNavPoint,
  PortfolioAmountsChartPaidInPoint,
} from "../PortfolioAmountsChart/PortfolioAmountsChart";
import {
  GenericPieChartItem,
  PortfolioGenericPieChart,
} from "../PortfolioGenericPieChart/PortfolioGenericPieChart";
import {
  QuarterSelect,
  useQuarterSelectManager,
} from "../QuarterSelect/QuarterSelect";
import { PortfolioOverviewStats } from "./PortfolioOverviewStats";
import { PortfolioOverviewStatsLoader } from "./PortfolioOverviewStatsLoader";

const pieChartsGridProps = {
  sm: 12,
  md: 6,
  lg: 4,
  xxl: 3,
};

/**
 * Text to display when the portfolio section is empty.
 */
export const portfolioEmptyQuarterText =
  "Nothing to show in the current quarter";

/**
 * Display an overview of the portfolio.
 */
export const PortfolioOverview: React.FC = () => {
  const {
    selectedQuarter,
    setSelectedQuarter,
    maybePortfolioSituation,
    loading: statsLoading,
  } = useQuarterSelectManager();

  const investorId = useInvestor()?.id;
  const {
    confirmedCutoffs,
    draftCutoffs,
    fetching: cutoffsLoading,
  } = useRetrieveCutoffs();

  const cutoffs = useMemo(
    () => [...confirmedCutoffs, ...draftCutoffs],
    [confirmedCutoffs, draftCutoffs],
  );

  const [{ data, fetching: amountsChartLoading }] = useQuery<
    PortfolioAmountsChartQuery,
    PortfolioAmountsChartQueryVariables
  >({
    query: PortfolioAmountsChartDocument,
    variables: {
      investorId: investorId ?? "",
      cutoffs: cutoffs.map(formatCutoffDate) ?? [],
      flags: [],
    },
    pause: !investorId || cutoffs.length === 0,
    context: disableSuspenseContext,
  });

  const isPortfolioEmpty = O.isNone(maybePortfolioSituation) && !statsLoading;

  const isPortfolioLoading =
    statsLoading ||
    O.isNone(maybePortfolioSituation) ||
    cutoffsLoading ||
    amountsChartLoading;

  const { navPoints, paidInPoints } = useMemo(() => {
    const situations = data?.investor.portfolioSituation ?? [];

    const navPoints = new Array<PortfolioAmountsChartNavPoint>();
    const paidInPoints = new Array<PortfolioAmountsChartPaidInPoint>();

    for (const {
      cutoffDate,
      totals: { navUsd, paidInUsd },
    } of situations) {
      const parsedCutoffDate = startOfQuarter(new Date(cutoffDate));

      navPoints.push({
        x: parsedCutoffDate,
        y: navUsd,
        paidIn: paidInUsd,
        label: "",
      });

      paidInPoints.push({
        x: parsedCutoffDate,
        y: paidInUsd,
      });
    }

    return {
      navPoints,
      paidInPoints,
    };
  }, [data]);

  const activeCutoff = useMemo(() => {
    const todayCutoff = formatCutoffDate(getTodayCutoff());

    return todayCutoff !== selectedQuarter
      ? new Date(selectedQuarter)
      : undefined;
  }, [selectedQuarter]);

  const isActiveCutoffDraft = useMemo(
    () =>
      !!draftCutoffs.find(
        (cutoff) => formatCutoffDate(cutoff) === selectedQuarter,
      ),
    [draftCutoffs, selectedQuarter],
  );

  const headingSectionRender = useMemo(
    () => (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        mt={-2}
      >
        <Box flex="0 0 auto" mt={2}>
          <Typography variant="h1">Portfolio Overview</Typography>
        </Box>

        <Box flex={{ xs: "0 0 100%", md: "0 0 230px" }} mt={2}>
          <QuarterSelect
            value={selectedQuarter}
            onChange={setSelectedQuarter}
          />
        </Box>
      </Stack>
    ),
    [selectedQuarter, setSelectedQuarter],
  );

  const {
    vintageChartAggregation,
    growthValueChartAggregation,
    fundSizeChartAggregation,
  } = useMemo(() => {
    if (O.isNone(maybePortfolioSituation))
      return {
        vintageChartAggregation: [],
        growthValueChartAggregation: [],
        fundSizeChartAggregation: [],
      };

    const vintageChartAggregationRecord: Record<string, number> = {};
    const fundSizeChartAggregationRecord = {
      "20.000m+": 0,
      "10.000m-20.000m": 0,
      "2.000m-10.000m": 0,
      "0-2.000m": 0,
    };
    let totalGrowth = 0;
    let totalValue = 0;

    for (const {
      allocation: { asset, amountUsd },
    } of maybePortfolioSituation.value.byAllocation) {
      // Aggregate the vintages.
      if (asset.vintage) {
        const vintageYear = new Date(asset.vintage).getFullYear().toString();

        vintageChartAggregationRecord[vintageYear] =
          (vintageChartAggregationRecord[vintageYear] ?? 0) + amountUsd;
      }

      // Aggregate the growth vs value.
      if (typeof asset.growthPercent === "number") {
        totalGrowth += asset.growthPercent * amountUsd;
        totalValue += (1 - asset.growthPercent) * amountUsd;
      }

      // Aggregate the fund size.
      if (typeof asset.sizeAmountUsd === "number") {
        if (asset.sizeAmountUsd >= 20_000_000_000) {
          fundSizeChartAggregationRecord["20.000m+"] += amountUsd;
        } else if (asset.sizeAmountUsd >= 10_000_000_000) {
          fundSizeChartAggregationRecord["10.000m-20.000m"] += amountUsd;
        } else if (asset.sizeAmountUsd >= 2_000_000_000) {
          fundSizeChartAggregationRecord["2.000m-10.000m"] += amountUsd;
        } else {
          fundSizeChartAggregationRecord["0-2.000m"] += amountUsd;
        }
      }
    }

    const vintageChartAggregation: Array<GenericPieChartItem> = Object.entries(
      vintageChartAggregationRecord,
    ).map(([name, amount]) => ({
      name,
      amount,
    }));

    const growthValueChartAggregation: Array<GenericPieChartItem> =
      totalGrowth === 0 && totalValue === 0
        ? []
        : [
            {
              name: "Growth",
              amount: totalGrowth,
            },
            {
              name: "Value",
              amount: totalValue,
            },
          ];

    const fundSizeChartAggregation: Array<GenericPieChartItem> = Object.entries(
      fundSizeChartAggregationRecord,
    )
      .map(([name, amount]) => ({
        name,
        amount,
      }))
      .filter(({ amount }) => amount > 0);

    return {
      vintageChartAggregation,
      growthValueChartAggregation,
      fundSizeChartAggregation,
    };
  }, [maybePortfolioSituation]);

  if (isPortfolioEmpty)
    return (
      <Stack direction="column" spacing={2} height="100%">
        <Box flex="0 0 auto">{headingSectionRender}</Box>

        <Box flex="1 1 100%">
          <NoDataPlaceholder message={portfolioEmptyQuarterText} />
        </Box>
      </Stack>
    );

  return (
    <>
      <Box>{headingSectionRender}</Box>

      <Box mt={2}>
        {!statsLoading && O.isSome(maybePortfolioSituation) ? (
          <PortfolioOverviewStats
            quarterPortfolioSituation={maybePortfolioSituation.value}
            isDraft={isActiveCutoffDraft}
          />
        ) : (
          <PortfolioOverviewStatsLoader />
        )}
      </Box>

      <Box mt={2}>
        {!isPortfolioLoading ? (
          <Paper>
            <Box p={2}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h3">
                  <UnderlineH3 color="chart100">Residual Value</UnderlineH3> and{" "}
                  <UnderlineH3 color="chart700">Paid-in</UnderlineH3> over time
                </Typography>

                <Typography variant="formLabel">
                  Everything in <strong>&apos;000 $</strong>
                </Typography>
              </Stack>

              <Box mt={1}>
                <PortfolioAmountsChart
                  navData={navPoints}
                  paidInData={paidInPoints}
                  activeCutoff={activeCutoff}
                />
              </Box>
            </Box>
          </Paper>
        ) : (
          <SkeletonLoader
            size={{
              // Hardcoded aspect ratio for the whole
              // box in which the chart is positioned.
              aspectRatio: "1966 / 624",
            }}
          />
        )}
      </Box>

      <Box mt={2}>
        {!isPortfolioLoading ? (
          <Grid container spacing={2}>
            <Grid {...pieChartsGridProps}>
              <Paper sx={{ height: "100%" }}>
                <Box p={2}>
                  <Typography variant="h3">Strategies</Typography>

                  <Box mt={2}>
                    <PortfolioGenericPieChart
                      data={
                        maybePortfolioSituation.value
                          .diversificationByAllocationAmount.strategies
                      }
                    />
                  </Box>
                </Box>
              </Paper>
            </Grid>

            <Grid {...pieChartsGridProps}>
              <Paper sx={{ height: "100%" }}>
                <Box p={2}>
                  <Typography variant="h3">Geographies</Typography>

                  <Box mt={2}>
                    <PortfolioGenericPieChart
                      data={
                        maybePortfolioSituation.value
                          .diversificationByAllocationAmount.geographies
                      }
                    />
                  </Box>
                </Box>
              </Paper>
            </Grid>

            <Grid {...pieChartsGridProps}>
              <Paper sx={{ height: "100%" }}>
                <Box p={2}>
                  <Typography variant="h3">Industries</Typography>

                  <Box mt={2}>
                    <PortfolioGenericPieChart
                      data={
                        maybePortfolioSituation.value
                          .diversificationByAllocationAmount.industries
                      }
                    />
                  </Box>
                </Box>
              </Paper>
            </Grid>

            {vintageChartAggregation.length > 0 ? (
              <Grid {...pieChartsGridProps}>
                <Paper sx={{ height: "100%" }}>
                  <Box p={2}>
                    <Typography variant="h3">Vintage</Typography>

                    <Box mt={2}>
                      <PortfolioGenericPieChart
                        data={vintageChartAggregation}
                      />
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            ) : undefined}

            {fundSizeChartAggregation.length > 0 ? (
              <Grid {...pieChartsGridProps}>
                <Paper sx={{ height: "100%" }}>
                  <Box p={2}>
                    <Typography variant="h3">Fund size</Typography>

                    <Box mt={2}>
                      <PortfolioGenericPieChart
                        data={fundSizeChartAggregation}
                      />
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            ) : undefined}

            {growthValueChartAggregation.length > 0 ? (
              <Grid {...pieChartsGridProps}>
                <Paper sx={{ height: "100%" }}>
                  <Box p={2}>
                    <Typography variant="h3">Style</Typography>

                    <Box mt={2}>
                      <PortfolioGenericPieChart
                        data={growthValueChartAggregation}
                      />
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            ) : undefined}
          </Grid>
        ) : (
          <SkeletonLoader size={{ height: 409 }} />
        )}
      </Box>
    </>
  );
};
