import React, { useMemo } from "react";
import { option as O, function as F } from "fp-ts";
import { useQuarterSelectManager } from "../QuarterSelect/QuarterSelect";
import * as U_Show from "@heritageholdings/lib-commons-finance/lib/show";
import * as U_M from "@heritageholdings/lib-commons-finance/lib/units/money";
import { TableColumn, tableFallbackValue } from "../../../core/v2/Table/Table";
import { SkeletonLoader } from "../../../core/v2/Loader/SkeletonLoader";
import { useAssetUrl } from "../../../../hooks/useAssetUrl";
import { formatCutoffDate, getTodayCutoff } from "../../../../utils/cutoff";
import { PageTable } from "../../../core/v2/PageTable/PageTable";
import { portfolioEmptyQuarterText } from "../PortfolioOverview/PortfolioOverview";
import { useRecoilValue } from "recoil";
import { enablePortfolioAudit } from "../../../../state/developer";
import { parseMoneyCurrency } from "../../../../utils/data/SimpleMoney";
import { GridRowId, GridApiCommon } from "@mui/x-data-grid-premium";
import { useInvestor } from "../../../../hooks/useInvestor";
import { generateQuarterlyReportLink } from "../../../../utils/documents";
import { useRetrieveCutoffs } from "../../../../hooks/useRetrieveCutoffs";
import { Typography } from "@mui/material";
import {
  genPortfolioAssetsTotals,
  genPortfolioAssetsTotalsRatios,
} from "./portfolioAssetsTotals";
import { Currency } from "@heritageholdings/lib-commons-finance/lib/units/MoneyNext";
import { Box } from "../../../core/v2/Box/Box";

type PortfolioAssetsRow = {
  sourcePaidInUsd: number;
  auditTrailJson: string;
  currency: U_M.Currency;

  id: string;
  fundId: string;
  fund: string;
  commitment: U_M.Money;
  distributed: U_M.Money;
  dpi: number;
  tvpi: number;
  paidIn: U_M.Money;
  profit: number;
  nav: U_M.Money;
  totalValue: U_M.Money;
  unfunded: U_M.Money;
  unpaid: U_M.Money;

  pinnedRowFundSubtitle?: string;
};

const retrieveRow = (
  api: React.MutableRefObject<GridApiCommon>,
  id: GridRowId | undefined,
) =>
  F.pipe(
    id,
    O.fromNullable,
    O.chain((id) => O.fromNullable(api.current.getRow<PortfolioAssetsRow>(id))),
  );

/**
 * Display the `assets` tab of the portfolio.
 */
export const PortfolioAssets: React.FC = () => {
  const investor = useInvestor();
  const { selectedQuarter, maybePortfolioSituation, loading } =
    useQuarterSelectManager();
  const { isDraftCutoff } = useRetrieveCutoffs();

  const isTodayQuarter = useMemo(
    () => selectedQuarter === formatCutoffDate(getTodayCutoff()),
    [selectedQuarter],
  );

  const getFundUrl = useAssetUrl({ fromPortfolio: true });
  const portfolioV2AuditEnabled = useRecoilValue(enablePortfolioAudit);

  const columns = useMemo<Array<TableColumn<PortfolioAssetsRow>>>(
    () => [
      {
        headerName: "Fund",
        field: "fund",
        flex: 2,
        minWidth: 200,
        customColumnKind: {
          kind: "internalLink",
          generateLink: ({ row }) => (row ? getFundUrl(row.fundId) : "#"),
        },
        pinnedRowRenderCell: ({ value, row }) => (
          <Box>
            <Typography variant="caption">{value}</Typography>

            <Box>
              {row?.pinnedRowFundSubtitle && (
                <Typography variant="body2">
                  {row.pinnedRowFundSubtitle}
                </Typography>
              )}
            </Box>
          </Box>
        ),
      },
      {
        headerName: "Commitment",
        field: "commitment",
        type: "Money",
        minWidth: 150,
      },
      {
        headerName: "Paid-in",
        field: "paidIn",
        type: "Money",
        minWidth: 150,
        customColumnKind: {
          kind: "valueWithWarning",
          generateMessage({ row: maybeRow }) {
            if (!maybeRow) return undefined;

            const currency = maybeRow.currency;

            const sourcePaidInUsd =
              maybeRow.sourcePaidInUsd > 0
                ? U_Show.currencyWebAppGeneric(
                    parseMoneyCurrency(currency),
                  ).show(maybeRow.sourcePaidInUsd)
                : undefined;

            if (!sourcePaidInUsd) return undefined;

            return `This allocation originates from a secondary transaction, with the paid-in amount comprising the payment made by previous owners of this allocation. (${sourcePaidInUsd})`;
          },
        },
      },
      {
        headerName: "Unfunded",
        field: "unfunded",
        type: "Money",
        minWidth: 150,
      },
      {
        headerName: "Distributions",
        field: "distributed",
        type: "Money",
        minWidth: 150,
        customColumnKind: {
          kind: "withTooltip",
          generateTooltip: ({ value, row }) =>
            typeof value === "number" && value > 0 && row ? (
              <>
                <strong>DPI:</strong> {U_Show.dpi.show(row.dpi)}
              </>
            ) : null,
        },
      },
      {
        headerName: `${isTodayQuarter ? "est." : ""} Residual Value`,
        field: "nav",
        type: "Money",
        minWidth: 170,
      },
      {
        headerName: isTodayQuarter ? "est. Total Value" : "Total Value",
        field: "totalValue",
        type: "Money",
        minWidth: 170,
      },
      {
        headerName: "Unpaid",
        field: "unpaid",
        type: "Money",
        minWidth: 150,
      },
      {
        headerName: "TVPI",
        field: "tvpi",
        valueFormatter: (value) => U_Show.fundMom.show(value as number),
        disableAggregation: true,
      },
      {
        headerName: isTodayQuarter ? "est. Profit" : "Profit",
        field: "profit",
        type: "number",
        minWidth: 130,
        valueFormatter: (value, { id }, _, api) =>
          F.pipe(
            retrieveRow(api, id),
            O.map(({ nav, currency }) =>
              U_Show.currencyFundProfit(parseMoneyCurrency(currency))(
                nav.toNormalizedAmount(),
              ).show(value as number),
            ),
            O.getOrElse(() => tableFallbackValue),
          ),
        customColumnKind: {
          kind: "withChip",
          generateChipColor: ({ value }) =>
            typeof value === "number" && value > 0
              ? {
                  backgroundColor: "statusPositive",
                  textColor: "invariantWhite",
                }
              : {
                  backgroundColor: "neutral500",
                  textColor: "invariantWhite",
                },
        },
      },
      ...(portfolioV2AuditEnabled
        ? [
            {
              headerName: "Audit",
              field: "auditTrailJson",
              customColumnKind: {
                kind: "portfolioAudit",
                generateJsonAudit: ({ value }) =>
                  typeof value === "string" ? value : JSON.stringify({}),
              },
            } satisfies TableColumn<PortfolioAssetsRow>,
          ]
        : []),
    ],
    [getFundUrl, isTodayQuarter, portfolioV2AuditEnabled],
  );

  const [rows, currencies] = useMemo<
    [Array<PortfolioAssetsRow>, Set<Currency>]
  >(() => {
    if (O.isNone(maybePortfolioSituation)) return [[], new Set()];

    const computedRows: Array<PortfolioAssetsRow> = [];
    const currencies = new Set<Currency>();

    maybePortfolioSituation.value.byAllocation.forEach((psfa, index) => {
      const currency = parseMoneyCurrency(psfa.allocation.currency);
      const annotated =
        maybePortfolioSituation.value.annotatedByAllocation[index];

      currencies.add(currency);

      computedRows.push({
        sourcePaidInUsd: psfa.sourcePaidInUsd,
        auditTrailJson: annotated?.trailJson_UNSTABLE ?? JSON.stringify({}),
        currency,
        id: `${psfa.allocation.asset.id}-${psfa.allocation.allocationDate}-${psfa.allocation.currency}`,
        fund: psfa.allocation.asset.name,
        fundId: psfa.allocation.asset.id,
        commitment: new U_M.Money({ [currency]: psfa.allocation.amountUsd }),
        distributed: new U_M.Money({ [currency]: psfa.distributedUsd }),
        dpi: psfa.dpi,
        tvpi: psfa.tvpi,
        paidIn: new U_M.Money({ [currency]: psfa.paidInUsd }),
        profit: psfa.profit,
        nav: new U_M.Money({ [currency]: psfa.navUsd }),
        totalValue: new U_M.Money({ [currency]: psfa.totalValue }),
        unfunded: new U_M.Money({ [currency]: psfa.unfunded }),
        unpaid: new U_M.Money({ [currency]: psfa.unpaid }),
      });
    });

    return [computedRows, currencies];
  }, [maybePortfolioSituation]);

  const totalsRows = useMemo<Array<PortfolioAssetsRow> | undefined>(() => {
    if (O.isNone(maybePortfolioSituation)) return undefined;

    const { totals } = maybePortfolioSituation.value;

    const aggregatedEur = genPortfolioAssetsTotals(
      maybePortfolioSituation.value,
      "EUR",
    );
    const aggregatedUsd = genPortfolioAssetsTotals(
      maybePortfolioSituation.value,
      "USD",
    );
    const ratiosEur = genPortfolioAssetsTotalsRatios(aggregatedEur, "EUR");
    const ratiosUsd = genPortfolioAssetsTotalsRatios(aggregatedUsd, "USD");

    // We show the grand total only if there are multiple currencies.
    const showGrandTotal = currencies.size > 1;

    const aggregatedCurrenciesTotals = [
      {
        id: "total-usd",
        fund: "Total $USD",
        fundId: "",
        currency: "USD" as const,
        auditTrailJson: "{}",

        ...aggregatedUsd,
        ...ratiosUsd,

        sourcePaidInUsd: 0,
        profit: aggregatedUsd.profit.toNormalizedAmount(),
      } satisfies PortfolioAssetsRow,
      {
        id: "total-eur",
        fund: "Total €EUR",
        fundId: "",
        currency: "EUR" as const,
        auditTrailJson: "{}",

        ...aggregatedEur,
        ...ratiosEur,

        sourcePaidInUsd: 0,
        profit: aggregatedEur.profit.toNormalizedAmount(),
      } satisfies PortfolioAssetsRow,
    ].filter(({ currency }) => currencies.has(currency));

    return showGrandTotal
      ? [
          ...aggregatedCurrenciesTotals,
          {
            id: "total-main",
            fund: "Grand Total $USD",
            fundId: "",
            currency: "USD" as const,
            auditTrailJson: "{}",

            commitment: new U_M.Money({ USD: totals.allocationAmountUsd }),
            distributed: new U_M.Money({ USD: totals.distributedUsd }),
            dpi: totals.dpi,
            tvpi: totals.tvpi,
            paidIn: new U_M.Money({ USD: totals.paidInUsd }),
            profit: totals.profitUsd,
            nav: new U_M.Money({ USD: totals.navUsd }),
            totalValue: new U_M.Money({ USD: totals.totalValue }),
            unfunded: new U_M.Money({ USD: totals.unfunded }),
            unpaid: new U_M.Money({ USD: totals.unpaid }),

            sourcePaidInUsd: 0,
          } satisfies PortfolioAssetsRow,
        ]
      : aggregatedCurrenciesTotals;
  }, [maybePortfolioSituation, currencies]);

  return (
    <PageTable
      title="Assets"
      kind="table"
      loading={loading}
      rows={rows}
      columns={columns}
      loaderComponent={<SkeletonLoader size={{ height: "table" }} />}
      customEmptyText={portfolioEmptyQuarterText}
      visibilityModel={{
        unpaid: false,
      }}
      exportLink={
        investor?.id &&
        selectedQuarter &&
        !isDraftCutoff(new Date(selectedQuarter))
          ? generateQuarterlyReportLink(investor.id, new Date(selectedQuarter))
          : undefined
      }
      pinnedBottomRows={totalsRows}
      defaultPagesIndex={1}
      quarterSelect
      downloadCsv
      exportHidden
    />
  );
};
